import { Component, OnInit } from '@angular/core';
import { Pet } from '../../models/pets';
import { PetService } from '../../services/pets.service';
import { Contact } from '../../models/api/contact';
import { ApiService } from '../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2'
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {


  id: string

  constructor(private router: Router) { }

  ngOnInit() {
  }


  submit() {
    if(this.id && this.id !== "") {
      this.router.navigate(["track"], {queryParams: {id: this.id}})
      this.id = ""
    }
  }

}
