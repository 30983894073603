import { Deserializable } from "../deserializable"
import { ContactInfo } from "./contactInfo"


export class Shipment implements Deserializable {
    shipmentID?: string
    sender: ContactInfo = new ContactInfo()
    receiver: ContactInfo = new ContactInfo()
    //Shipment specific info
    from: string
    to: string
    status: string
    sType: string
    weight: string
    freightMode: string
    vessel: string
    departureDate: string
    pickupDate: string
    currentLocation: string
    description: string
    comment: string

    deserialize(input: any): this {
        this.sender = new ContactInfo().deserialize(input.sender)
        this.receiver = new ContactInfo().deserialize(input.receiver)
        Object.assign(this, input);
        return this;
    }
}