import { Deserializable } from "../deserializable"

export class ContactInfo implements Deserializable{
    name: string
    phone: string
    state: string
    address: string
    country: String

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
} 