import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContactInfo } from '../../models/api/contactInfo';
import { Shipment } from '../../models/api/shipment';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent implements OnInit {

  shipment: Shipment = new Shipment()
  isLoading = true
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private apiService: ApiService
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      let id: string = params["id"]
      if (id && id != "") {
        this.apiService.getShipment(id).subscribe((value) => {
          this.isLoading = false
          this.shipment = value
        },
          (err: HttpErrorResponse) => {
            this.router.navigate(["/404"])
          })
      }
      else {
        this.router.navigate(["/404"])
      }
    })
  }

}
