interface Scripts {
    name: string;
    src: string;
}  
export const ScriptStore: Scripts[] = [
    {name: 'jquery', src: 'assets/js/jquery.js'},
    {name: 'owl', src: 'assets/js/owl.js'},
    {name: 'script', src: 'assets/js/script.js'},
    {name: 'appear', src: 'assets/js/appear.js'},
    {name: 'scrollbar', src: 'assets/js/scrollbar.js'},
    {name: 'fancybox', src: 'assets/js/jquery.fancybox.js'},
];
