import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Contact } from '../../models/api/contact';
import { Pet } from '../../models/pets';
import { ApiService } from '../../services/api.service';
import { PetService } from '../../services/pets.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contact: Contact = new Contact();
  isLoading = false;
  buttonMsg = "Send Message";
  pets: Pet[] = new Array()
  availablePets: Pet[] = new Array()
  private pathToAssets: string = "../../assets/";


  constructor(private productService: PetService, private apiService: ApiService) {
    
  }

  ngOnInit() {
    this.availablePets = this.productService.findAll().filter(p => !p.adopted)
    this.pets = this.productService.findRandom(12);
    this.contact.subject = this.availablePets[0].name
  }

  printPrice(price : number)
  {
    var price_parts = price.toString().split(".");
    price_parts[0] = price_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return price_parts.join(".");
  }

  submit() {
    this.buttonMsg = "Loading..."
    this.apiService.contact(this.contact)
    .subscribe((data: any) => {
      this.isLoading = true;
      Swal({
        title: 'Thanks!',
        text: 'Thank you! Your message has been received. We will contact you as soon as possible',
        type: 'success',
        confirmButtonText: 'Ok',
      })
      this.contact = new Contact();
      this.isLoading = false;
      this.buttonMsg = "Send Message";
    },
      (err: HttpErrorResponse) => {
        this.isLoading = false;
        Swal({
          title: 'Error!',
          text: 'Unable to send message. Make sure you are connected to the internet',
          type: 'warning',
          confirmButtonText: 'Ok!',
        })
        this.buttonMsg = "Send Message";
      });

  }
}