import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  id: string

  constructor(private router: Router) { }
  
    ngOnInit() {
    }
  
    submit() {
      if(this.id && this.id !== "") {
        this.router.navigate(["track/result"], {queryParams: {id: this.id}})
        this.id = ""
      }
    }

}
